<template>
  <CRow>
    <CCol>
      <div class="center-spinner" v-if="nLoading == 0">
        <CSpinner
          color="primary"
          grow
        />
      </div>
      <div v-else>
        <CCard>
          <CCardHeader>
            <b>Boardcast History Detail</b>
          </CCardHeader>
          <CCardBody>
            <dl class="row">
              <dt class="col-sm-3">ID</dt>
              <dd class="col-sm-9">
                <b>{{ brcHisDetail._id }}</b>
              </dd>

              <dt class="col-sm-3">Created By</dt>
              <dd class="col-sm-9">
                {{ brcHisDetail.user_id }}
              </dd>

              <dt class="col-sm-3">List ID</dt>
              <dd class="col-sm-9">
                <template v-if="brcHisDetail.condition">
                  {{ brcHisDetail.condition.app_ids }}
                </template>
              </dd>

              <dt class="col-sm-3">Broadcast Message</dt>
              <dd class="col-sm-9">
                <template v-if="brcHisDetail.condition">
                  {{ brcHisDetail.condition.message }}
                </template>
              </dd>
            </dl>
          </CCardBody>
        </CCard>

        <CCard>
          <CCardHeader>
            <b>Progress</b>
          </CCardHeader>
          <CCardBody>
            <dl class="row">
              <dt class="col-sm-3">Status</dt>
              <dd class="col-sm-9">
                <template v-if="brcHisDetail.progress">
                  <b>{{ brcHisDetail.progress.status }} </b>
                  <div
                    class="spinner-border spinner-border-sm login-spinner"
                    role="status"
                    :hidden="brcHisDetail.progress.status !== 'IN_PROGRESS'"
                    >
                    <span class="sr-only">Loading...</span>
                  </div>
                </template>
              </dd>

              <dt class="col-sm-3">Processed users</dt>
              <dd class="col-sm-9">
                <template v-if="brcHisDetail.progress">
                  {{ brcHisDetail.progress.processed_users + " / " + brcHisDetail.progress.total_users }}
                  <CProgress v-if="brcHisDetail.progress.processed_users && brcHisDetail.progress.total_users"
                    :value="brcHisDetail.progress.processed_users"
                    :max="brcHisDetail.progress.total_users"
                    show-percentage animated
                    >
                  </CProgress>
                </template>
              </dd>

              <dt class="col-sm-3">Total sent</dt>
              <dd class="col-sm-9">
                <template v-if="brcHisDetail.progress">
                  {{ brcHisDetail.progress.n_sent }}
                </template>
              </dd>

              <dt class="col-sm-3">Total failed</dt>
              <dd class="col-sm-9">
                <template v-if="brcHisDetail.progress">
                  {{ brcHisDetail.progress.n_failed }}
                </template>
              </dd>
            </dl>
          </CCardBody>
        </CCard>

        <CCard>
          <CCardHeader>
            <b>Summary</b>
          </CCardHeader>
          <CCardBody>
            <dl class="row">
              <dt class="col-sm-3">Total User</dt>
              <dd class="col-sm-9">
                <template v-if="brcHisDetail.result">
                  {{ brcHisDetail.result.total_users }}
                </template>
              </dd>

              <dt class="col-sm-3">Total Cost</dt>
              <dd class="col-sm-9">
                <template v-if="brcHisDetail.result">
                  {{ Math.round(brcHisDetail.result.total_cost * 100) / 100 }}
                </template>
              </dd>
            </dl>
            <table class="table table-bordered" v-if="brcHisDetail.result">
              <thead>
                <tr>
                  <th scope="col">Country</th>
                  <th scope="col">Total Users</th>
                  <th scope="col">Unit Prices</th>
                  <th scope="col">Total Cost</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, name) in brcHisDetail.result.details" :key="name">
                  <td>{{ name }}</td>
                  <td>{{ item.total_users }}</td>
                  <td>{{ item.unit_price }}</td>
                  <td>{{ Math.round(item.total_users * item.unit_price * 100) / 100 }}</td>
                </tr>
              </tbody>
            </table>
          </CCardBody>
        </CCard>
      </div>
    </CCol>
  </CRow>
</template>


<script>
import AxiosBase from '../../api/AxiosBase'

export default {
  name: 'BoardcastHistoryDetail',
  created() {
    this.getBroadcastHistoryDetail();
  },
  data () {
    return {
      nLoading: 0,
      brcHisDetail: {}
    }
  },
  methods: {
    getBroadcastHistoryDetail() {
      var _this = this;
      var oid = _this.$route.params.id;
      if (!oid) {
        return;
      }
      AxiosBase.get("/broadcast/history/get", {
        params: {
          oid: oid
        }
      })
      .catch(function(error) {
        console.log(error);
        _this.loading = false;
      })
      .then(function(res) {
        var resCode = res?.data?.code;
        var resBrcHisDetail = res?.data?.value;
        if (resCode >= 0 && resBrcHisDetail) {
          _this.brcHisDetail = resBrcHisDetail;

          var status = resBrcHisDetail?.progress?.status;
          if (status === "IN_PROGRESS") {
            setTimeout(() => _this.getBroadcastHistoryDetail(), 2000);
          }
        }

        _this.nLoading = _this.nLoading + 1;
      });
    }
  }
}
</script>

<style>
.center-spinner {
  margin: auto;
  width: 0%;
}
</style>